import { SET_INPUT_VALUE_FOR_CREATE_STUDENT_FORM } from '../../actions/createStudent/createStudentActions';

const initialState = {
  studentId: '',
  firstName: '',
  middleName: '',
  surname: '',
  knownAs: '',
  studentType: '',
  programme: '',
  cohort: '',
  group: '',
  mentor: '',
  personalTutor: '',
  mentors: ['None', 'Brian Brain'],
  personalTutors: ['None', 'Personal Man'],
};

const createStudentReducer = (state = { ...initialState }, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case SET_INPUT_VALUE_FOR_CREATE_STUDENT_FORM:
      nextState[action.payload.key] = action.payload.value;
      return nextState;

    default:
      return {
        ...state,
      };
  }
};

export default createStudentReducer;
