import { SET_TOGGLED_HEADER, GET_STUDENT_TYPES } from '../../actions/studentsDataTable/studentsDataTableActions';
import setToggle from '../../helpers/setDataTableToggle';

const intialState = {
  studentTypes: {
    error: null,
    loading: null,
    data: null,
    refetch: null,
  },
  toggledHeader: {
    label: '',
    toggle: null,
  },
};

const studentsDataTableReducer = (state = intialState, action) => {
  const currentToggle = state.toggledHeader.toggle;
  const currentLabel = state.toggledHeader.label;
  switch (action.type) {
    case SET_TOGGLED_HEADER:
      state.toggledHeader = {
        label: action.payload,
        toggle: setToggle(currentToggle, currentLabel, action.payload),
      };
      return state;
    case GET_STUDENT_TYPES:
      state.studentTypes = action.payload;
      return state;
    default:
      return {
        ...state,
      };
  }
};

export default studentsDataTableReducer;
