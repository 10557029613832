export const SET_CURRENT_PROGRAMME = 'SET_CURRENT_PROGRAMME';
export const setCurrentProgramme = data => ({
  type: SET_CURRENT_PROGRAMME,
  payload: data,
});

export const SET_FORMIKPROPS_FOR_CURRENT_PROGRAMME = 'SET_FORMIKPROPS_FOR_CURRENT_PROGRAMME';
export const setFormikpropsForProgramme = formikProps => ({
  type: SET_FORMIKPROPS_FOR_CURRENT_PROGRAMME,
  payload: formikProps,
});
