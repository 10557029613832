import { TOGGLE_DISPLAY_NEW_PROGRAMME_MODAL } from '../../actions/createProgramme/createProgrammeActions';

const initialState = {
  displayModal: false,
};

const createProgrammeReducer = (state = { ...initialState }, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case TOGGLE_DISPLAY_NEW_PROGRAMME_MODAL:
      nextState.displayModal = action.payload;
      return nextState;

    default:
      return nextState;
  }
};

export default createProgrammeReducer;
