/* eslint-disable no-confusing-arrow */
import {
  ENQUEUE_NOTIFICATION,
  CLOSE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
} from '../../actions/notifications/notificationActions';

const initialState = {
  notifications: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(notification => action.dismissAll
          || notification.key === action.key
          ? { ...notification, dismissed: true }
          : { ...notification }),
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
      };

    case REMOVE_ALL_NOTIFICATIONS:
      return initialState;

    default:
      return state;
  }
};

export default notificationsReducer;
