import {
  SET_CURRENT_MODULE,
  SET_FORMIKPROPS_FOR_CURRENT_MODULE,
} from '../../actions/currentModule/currentModuleActions';

const initialState = {
  id: null,
  title: '',
  code: '',
  awardingInstitutionName: '',
  studyType: '',
  creditPoints: '',
  moduleCount: '',
  exitAward: '',
  currentStatus: '',
  formikProps: null,
  rationaleAndLinks: '',
  indicativeContent: '',
  aims: '',
  tutorStaffId: null,
};

const currentModuleReducer = (state = { ...initialState }, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case SET_CURRENT_MODULE:
      nextState.id = action.payload.id;
      nextState.title = action.payload.title;
      nextState.code = action.payload.code;
      nextState.creditPoints = action.payload.creditPoints;

      nextState.rationaleAndLinks = action.payload.rationaleAndLinks || '';
      nextState.indicativeContent = action.payload.indicativeContent || '';
      nextState.aims = action.payload.aims || '';
      nextState.tutorStaffId = action.payload.tutorStaffId || null;

      return nextState;

    case SET_FORMIKPROPS_FOR_CURRENT_MODULE:
      nextState.formikProps = action.payload;
      return nextState;

    default:
      return {
        ...state,
      };
  }
};

export default currentModuleReducer;
