import { UPDATE_LOGS_FROM_DATE, UPDATE_LOGS_TO_DATE } from '../../actions/logs/logsActions';

const initialState = {
  fromDate: null,
  toDate: null,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOGS_FROM_DATE:
      return {
        ...state,
        fromDate: action.payload,
      };
    case UPDATE_LOGS_TO_DATE:
      return {
        ...state,
        toDate: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default notificationsReducer;
