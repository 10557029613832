export const TOGGLE_DISPLAY_NEW_COMMUNICATION_MODAL = 'TOGGLE_DISPLAY_NEW_COMMUNICATION_MODAL';
export const toggleDisplayNewCommunicationModal = isOpen => ({
  type: TOGGLE_DISPLAY_NEW_COMMUNICATION_MODAL,
  payload: isOpen,
});

export const TOGGLE_DISPLAY_EDIT_COMMUNICATION_MODAL = 'TOGGLE_DISPLAY_EDIT_COMMUNICATION_MODAL';
export const toggleDisplayEditCommunicationModal = payload => ({
  type: TOGGLE_DISPLAY_EDIT_COMMUNICATION_MODAL,
  payload,
});
