import {
  ADD_NEW_MODULE,
  UPDATE_MODULE,
  REMOVE_MODULE,
  SET_DRAGGED_MODULES,
  RESET_COMPULSORY_MODULES,
  SET_MODULES,
  UPDATE_NEW_MODULE,
} from '../../actions/compulsoryModules/compulsoryModulesActions';

const initialState = {
  modulesArray: [],
  moduleCredits: 0,
};

export const updateModulesList = (arr, payload) => arr.map(module => {
  if (module.id === payload.id) {
    return payload;
  }
  return module;
});

export const updateCredits = arr => arr.reduce((acc, val) => acc + Number(val.creditPoints), 0);

export const removeModule = (arr, id) => arr.filter(module => module.id !== id);

const compulsoryModulesBuilderReducer = (state = { ...initialState }, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case ADD_NEW_MODULE:
      nextState.modulesArray = [...nextState.modulesArray, action.payload];
      return nextState;

    case UPDATE_MODULE: {
      const updatedModulesList = updateModulesList(nextState.modulesArray, action.payload);
      const updatedCredits = updateCredits(updatedModulesList);

      nextState.moduleCredits = updatedCredits;
      nextState.modulesArray = updatedModulesList;
      return nextState;
    }

    case UPDATE_NEW_MODULE: {
      const moduleToUpdate = nextState.modulesArray.filter(mod => mod.id === action.payload.id)[0];
      moduleToUpdate[action.payload.key] = action.payload.value;

      const updatedModuleList = [
        ...nextState.modulesArray.filter(mod => mod.id !== action.payload.id),
        moduleToUpdate,
      ];

      nextState.modulesArray = updatedModuleList;

      return nextState;
    }

    case REMOVE_MODULE: {
      const updatedModulesList = removeModule(nextState.modulesArray, action.payload.id);
      const updatedCredits = updateCredits(updatedModulesList);

      nextState.moduleCredits = updatedCredits;
      nextState.modulesArray = updatedModulesList;
      return nextState;
    }

    case SET_DRAGGED_MODULES: {
      const updatedModulesList = removeModule(
        nextState.modulesArray,
        action.payload.draggedModule.id,
      );
      updatedModulesList.splice(action.payload.index, 0, action.payload.draggedModule);

      nextState.modulesArray = updatedModulesList;
      return nextState;
    }

    case SET_MODULES: {
      nextState.modulesArray = action.payload;

      return nextState;
    }

    case RESET_COMPULSORY_MODULES: {
      return initialState;
    }

    default:
      return nextState;
  }
};

export default compulsoryModulesBuilderReducer;
