import {
  TOGGLE_DISPLAY_NEW_COMMUNICATION_MODAL,
  TOGGLE_DISPLAY_EDIT_COMMUNICATION_MODAL,
} from '../../actions/studentCommunications/studentCommunicationsActions';

const initialState = {
  displayModal: false,
  isInEditMode: false,
  entry: null,
};

const studentCommunicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DISPLAY_NEW_COMMUNICATION_MODAL:
      return {
        ...state,
        displayModal: action.payload,
        isInEditMode: false,
      };
    case TOGGLE_DISPLAY_EDIT_COMMUNICATION_MODAL:
      return {
        ...state,
        displayModal: action.payload.displayModal,
        isInEditMode: action.payload.isInEditMode,
        entry: action.payload.entry,
      };
    default:
      return {
        ...state,
      };
  }
};

export default studentCommunicationsReducer;
