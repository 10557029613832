import {
  combineReducers,
} from 'redux';
import {
  connectRouter,
} from 'connected-react-router';
import userInfoReducer from './userInfo/userInfoReducer';
import mobileReducer from './mobile/mobileReducer';
import notificationsReducer from './notifications/notificationsReducer';
import studentsDataTableReducer from './studentsDataTable/studentsDataTableReducer';
import furtherDetailsReducer from './furtherDetails/furtherDetailsReducer';
import createStudentReducer from './createStudent/createStudentReducer';
import studentJourneyReducer from './studentJourney/studentJourneyReducer';
import currentStudentReducer from './currentStudent/currentStudentReducer';
import communicationsReducer from './communications/communicationsReducer';
import studentCommunicationsReducer from './studentCommunications/studentCommunicationsReducer';
import logsReducer from './logs/logsReducer';
import modulesDataTableReducer from './modulesDataTable/modulesDataTableReducer';
import uploadFileToItemReducer from './uploadFileToItem/uploadFileToItemReducer';
import programmesDataTableReducer from './programmesDataTable/programmesDataTableReducer';
import currentProgrammeReducer from './currentProgramme/currentProgrammeReducer';
import currentModuleReducer from './currentModule/currentModuleReducer';
import createProgrammeReducer from './createProgramme/createProgrammeReducer';
import compulsoryModulesBuilderReducer from './compulsoryModulesBuilder/compulsoryModulesBuilderReducer';
import clusterSelectReducer from './clusterSelect/clusterSelectReducer';
import currentClusterReducer from './currentCluster/currentClusterReducer';
import createStudentValidationReducer from './createStudentValidation/createStudentValidationReducer';

export default history => combineReducers({
  clusterSelect: clusterSelectReducer,
  compulsoryModulesBuilder: compulsoryModulesBuilderReducer,
  communications: communicationsReducer,
  currentCluster: currentClusterReducer,
  currentProgramme: currentProgrammeReducer,
  currentModule: currentModuleReducer,
  currentStudent: currentStudentReducer,
  createProgramme: createProgrammeReducer,
  createStudentForm: createStudentReducer,
  furtherDetails: furtherDetailsReducer,
  logs: logsReducer,
  mobileSettings: mobileReducer,
  modulesDataTable: modulesDataTableReducer,
  notifications: notificationsReducer,
  programmesTable: programmesDataTableReducer,
  router: connectRouter(history),
  studentsDataTable: studentsDataTableReducer,
  studentJourney: studentJourneyReducer,
  studentCommunications: studentCommunicationsReducer,
  uploadFile: uploadFileToItemReducer,
  userInfo: userInfoReducer,
  createStudentValidation: createStudentValidationReducer,
});
