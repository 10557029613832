import {
  SET_CURRENT_PROGRAMME,
  SET_FORMIKPROPS_FOR_CURRENT_PROGRAMME,
} from '../../actions/currentProgramme/currentProgrammeActions';

const initialState = {
  title: '',
  code: '',
  awardingInstitutionName: '',
  studyType: '',
  creditPoints: '',
  moduleCount: '',
  exitAward: '',
  currentStatus: '',
  formikProps: {
    submitForm: null,
    values: {
      creditPoints: null,
    },
  },
  isPublishable: false,
};

const currentProgrammeReducer = (state = { ...initialState }, action) => {
  let nextState = { ...state };

  switch (action.type) {
    case SET_CURRENT_PROGRAMME:
      nextState = {
        ...nextState,
        ...action.payload,
      };
      return nextState;

    case SET_FORMIKPROPS_FOR_CURRENT_PROGRAMME:
      nextState.formikProps = action.payload;
      return nextState;

    default:
      return {
        ...state,
      };
  }
};

export default currentProgrammeReducer;
