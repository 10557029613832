import upload from './helpers/uploadFunction';
import { enqueueNotification } from '../notifications/notificationActions';

/* ************************************************** */
/* ******************** ACTIONS ********************* */
/* ************************************************** */

export const UPLOAD_FILE_BEGIN = 'UPLOAD_FILE_BEGIN';
export const uploadFileBegin = id => ({
  type: UPLOAD_FILE_BEGIN,
  payload: id,
});

export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const uploadFileSuccess = data => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: data,
});

export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const uploadFileFailure = data => ({
  type: UPLOAD_FILE_FAILURE,
  payload: data,
});

export const REMOVE_FILE_FROM_UPLOAD_ARRAY = 'REMOVE_FILE_FROM_UPLOAD_ARRAY';
export const removeFileFromUploadArray = data => ({
  type: REMOVE_FILE_FROM_UPLOAD_ARRAY,
  payload: data,
});

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const clearUploadError = () => ({
  type: CLEAR_ERROR,
});

export const CLEAN_FILE_UPLOAD_ARRAY = 'CLEAN_FILE_UPLOAD_ARRAY';
export const cleanUpFileUploads = () => ({
  type: CLEAN_FILE_UPLOAD_ARRAY,
});

/* ************************************************** */
/* ***************** ACTION CREATOR ***************** */
/* ************************************************** */

export const uploadFile = ({
  itemType,
  studentId,
  files,
  token,
  isUpdate,
  programmeId,
  originalApplication,
  componentId,
  staffId,
}) => async dispatch => {
  dispatch(uploadFileBegin(componentId));
  const promises = [];

  files.forEach(file => {
    promises.push(
      upload({
        itemType,
        studentId,
        file,
        token,
        isUpdate,
        programmeId,
        originalApplication,
        componentId,
        staffId,
      }),
    );
  });

  return Promise.all(promises)
    .then(response => {
      if (response[0] && response[0].errorCode == null) {
        dispatch(uploadFileSuccess({ response, componentId }));
        return response;
      }
      dispatch(
        enqueueNotification({
          message: response.message || 'Problem uploading file',
          key: 'mutation-error',
          options: {
            variant: 'error',
          },
        }),
      );
      return dispatch(uploadFileFailure({ response, componentId }));
    })
    .catch(error => {
      dispatch(uploadFileFailure(error));
      dispatch(
        enqueueNotification({
          message: error.message || 'Problem uploading file',
          key: 'mutation-error',
          options: {
            variant: 'error',
          },
        }),
      );
    });
};
