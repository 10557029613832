import { post } from './api';
import buildEndpointUrl from './buildEndpointUrl';
import convertFileType from './convertFileType';
import { createBlockBlobUrl, uploadToAzure } from './azureFunctions';

/* ************************************************** */
/* ****************** UPLOAD FUNC ******************* */
/* ************************************************** */

const upload = async ({
  studentId,
  itemType,
  file,
  token,
  isUpdate,
  programmeId,
  originalApplication,
  staffId,
  // eslint-disable-next-line
}) => {
  try {
    // build endpoint string
    const url = buildEndpointUrl({
      studentId,
      itemType,
      isUpdate,
      programmeId,
      staffId,
    });

    const fileObj = {
      name: file.name,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      fileType: convertFileType(file.type),
      isPublic: false,
      uploadType: itemType,
    };

    if (itemType !== 'add-staff') {
      fileObj.studentId = studentId;
    }

    if (itemType === 'add-staff') {
      fileObj.uploadType = 'avatar';
    }

    if (originalApplication) {
      fileObj.originalApplication = true;
    } else {
      fileObj.originalApplication = false;
    }

    // hit our endpoint to get url and token
    const filesCall = await post(url, fileObj, token);

    // parse response from our API
    const resBody = async () => filesCall.json();
    const body = await resBody();

    if (body.error == null) {
      // create block blob url
      const blockBlobUrl = createBlockBlobUrl({ body: body.data });

      // upload file to Azure
      // eslint-disable-next-line
      return await uploadToAzure(file, blockBlobUrl).then(async azureResponse => {
        if (azureResponse.errorCode == null) {
          const uploadedFile = {
            id: body.data.id,
            name: file.name,
            lastModifiedDate: file.lastModifiedDate,
            size: file.size,
            fileType: file.type,
            isPublic: false,
            uploadType: itemType,
          };

          if (itemType !== 'add-staff') {
            uploadedFile.studentId = studentId;
          }

          if (originalApplication) {
            uploadedFile.originalApplication = true;
          }

          return uploadedFile;
        }
      });
    }
  } catch (error) {
    return error;
  }
};

export default upload;
