import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import client from './apolloClient';

function NMiTEApolloProvider({ children }) {
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}

export default NMiTEApolloProvider;
