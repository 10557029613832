import settings from '../../../settings';

export const getBaseApiUrl = () => settings.apiUrl.split('/gateway/graphql')[0];

const buildEndpointUrl = ({
  studentId, itemType, programmeId, staffId,
}) => {
  const apiURL = getBaseApiUrl();

  switch (itemType) {
    case 'add-staff':
      // return `http://localhost:3002/staff/${staffId}/avatar/files?fields[]=id&fields[]=link`;
      return `${apiURL}/users/staff/${staffId}/avatar/files?fields[]=id&fields[]=link`;
    case 'support-requirements':
    case 'submission-requests':
      // return `http://localhost:3002/students/${studentId}/${itemType}/files?fields[]=id&fields[]=link`;
      return `${apiURL}/users/students/${studentId}/${itemType}/files?fields[]=id&fields[]=link`;

    case 'student-supporting-information':
      // return `http://localhost:3002/students/${studentId}/${itemType}/files?fields[]=id&fields[]=link`;
      return `${apiURL}/users/students/${studentId}/identification/files?fields[]=id&fields[]=link`;

    case 'communications':
      // return 'http://localhost:3005/communications/files?fields[]=id&fields[]=link';
      return `${apiURL}/logger/communications/files?fields[]=id&fields[]=link`;

    case 'programme-supporting-information':
      // return `http://localhost:3006/${programmeId}/files?fields[]=id&fields[]=link`;
      return `${apiURL}/programmes/${programmeId}/files?fields[]=id&fields[]=link`;

    case 'avatar':
      // return `http://localhost:3002/${studentId}/avatar/files?fields[]=id&fields[]=link`;
      return `${apiURL}/users/students/${studentId}/avatar/files?fields[]=id&fields[]=link`;

    case 'application-journey':
      // return `http://localhost:3002/students/${studentId}/applications/changes/files?fields[]=id&fields[]=link`;
      return `${apiURL}/users/students/${studentId}/applications/changes/files?fields[]=id&fields[]=link`;

    default:
      return new Error('Unknown itemType supplied to function');
  }
};

export default buildEndpointUrl;
