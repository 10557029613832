import React from 'react';
import { SnackbarProvider } from 'notistack';

import Notifier from './notifier';

import styles from './notistackProvider.styles';

function NotistackProvider({ children }) {
  const classes = styles();
  return (
    <SnackbarProvider
      maxSnack={20}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      <Notifier />
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
