/*
 ********************************************************************
 *** For notification documentation please see the following link ***
 ***            https://iamhosseindhv.com/notistack               ***
 ********************************************************************
 */

export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
export const enqueueNotification = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_NOTIFICATION,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const closeNotification = key => ({
  type: CLOSE_NOTIFICATION,
  dismissAll: !key,
  key,
});

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const removeNotification = key => ({
  type: REMOVE_NOTIFICATION,
  key,
});

export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';
export const removeAllNotifications = () => ({
  type: REMOVE_ALL_NOTIFICATIONS,
});
