import { SET_COMMUNICATION_TOGGLE } from '../../actions/communications/communicationsActions';
import setToggle from '../../helpers/setDataTableToggle';

const initialState = {
  toggledHeader: {
    label: '',
    toggle: null,
  },
};

const communicationsReducer = (state = initialState, action) => {
  const currentToggle = state.toggledHeader.toggle;
  const currentLabel = state.toggledHeader.label;
  switch (action.type) {
    case SET_COMMUNICATION_TOGGLE:
      state.toggledHeader = {
        label: action.payload,
        toggle: setToggle(currentToggle, currentLabel, action.payload),
      };
      return state;
    default:
      return {
        ...state,
      };
  }
};

export default communicationsReducer;
