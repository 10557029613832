export const UPDATE_LOGS_FROM_DATE = 'UPDATE_LOGS_FROM_DATE';
export const updateLogsFromDate = value => ({
  type: UPDATE_LOGS_FROM_DATE,
  payload: value,
});

export const UPDATE_LOGS_TO_DATE = 'UPDATE_LOGS_TO_DATE';
export const updateLogsToDate = value => ({
  type: UPDATE_LOGS_TO_DATE,
  payload: value,
});
