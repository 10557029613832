export function post(endpoint, payload, token, options = {}) {
  return fetch(`${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      authorization: `bearer ${token}`,
      ...options.headers,
      'Content-Type': 'application/json',
    },
  });
}

export function patch(endpoint, payload, token, options = {}) {
  return fetch(`${endpoint}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      authorization: `bearer ${token}`,
      ...options.headers,
      'Content-Type': 'application/json',
    },
  });
}
