import { UPDATE_STUDENT_VALIDATION } from '../../actions/createStudentValidation/createStudentValidationActions';

const initialState = {
  genericInformation: true,
  sensitiveInformation: true,
};

const createStudentValidationReducer = (state = { ...initialState }, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case UPDATE_STUDENT_VALIDATION:
      nextState[action.payload.key] = action.payload.value;
      return nextState;

    default:
      return {
        ...state,
      };
  }
};

export default createStudentValidationReducer;
