export const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE';
export const setCurrentModule = data => ({
  type: SET_CURRENT_MODULE,
  payload: data,
});

export const SET_FORMIKPROPS_FOR_CURRENT_MODULE = 'SET_FORMIKPROPS_FOR_CURRENT_MODULE';
export const setSubmitFunction = formikProps => ({
  type: SET_FORMIKPROPS_FOR_CURRENT_MODULE,
  payload: formikProps,
});
