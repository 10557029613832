import {
  SET_ASSESSMENT_OVERVIEW_TOGGLE,
  ASSESSMENT_OVERVIEW_SEARCH_ONCHANGE,
  RESET_ASSESSMENT_OVERVIEW_FILTERS,
} from '../../actions/studentJourney/studentJourneyActions';
import setToggle from '../../helpers/setDataTableToggle';

const intialState = {
  toggledAssessmentOverviewHeader: {
    label: '',
    toggle: null,
  },
  assessmentOverviewColumnSearch: {
    module: '',
    assessmentType: '',
    grade: '',
    pointsAvailable: '',
    mark: '',
  },
};

const studentJourneyReducer = (state = intialState, action) => {
  const currentToggle = state.toggledAssessmentOverviewHeader.toggle;
  const currentLabel = state.toggledAssessmentOverviewHeader.label;
  switch (action.type) {
    case SET_ASSESSMENT_OVERVIEW_TOGGLE:
      state.toggledAssessmentOverviewHeader = {
        label: action.payload,
        toggle: setToggle(currentToggle, currentLabel, action.payload),
      };
      return state;

    case ASSESSMENT_OVERVIEW_SEARCH_ONCHANGE: {
      state.assessmentOverviewColumnSearch[action.payload.header] = action.payload.value;
      return state;
    }

    case RESET_ASSESSMENT_OVERVIEW_FILTERS: {
      return {
        toggledAssessmentOverviewHeader: {
          label: '',
          toggle: null,
        },
        assessmentOverviewColumnSearch: {
          module: '',
          assessmentType: '',
          grade: '',
          pointsAvailable: '',
          mark: '',
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default studentJourneyReducer;
