import { SET_TOGGLED_HEADER_PROGRAMMES_TABLE, RESET_TOGGLED_HEADER_PROGRAMMES_TABLE } from '../../actions/programmesDataTable/programmesDataTableActions';
import setToggle from '../../helpers/setDataTableToggle';

const initialState = {
  toggledHeader: {
    label: '',
    toggle: null,
  },
};

const programmesDataTableReducer = (state = initialState, action) => {
  const currentToggle = state.toggledHeader.toggle;
  const currentLabel = state.toggledHeader.label;
  switch (action.type) {
    case SET_TOGGLED_HEADER_PROGRAMMES_TABLE:
      state.toggledHeader = {
        label: action.payload,
        toggle: setToggle(currentToggle, currentLabel, action.payload),
      };
      return state;

    case RESET_TOGGLED_HEADER_PROGRAMMES_TABLE:
      state.toggledHeader = {
        label: '',
        toggle: null,
      };
      return state;
    default:
      return { ...state };
  }
};

export default programmesDataTableReducer;
