import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import appTheme from './App.theme';

function NMiTEThemeProvider({ children }) {
  return (
    <ThemeProvider theme={appTheme}>
      {children}
    </ThemeProvider>
  );
}

export default NMiTEThemeProvider;
