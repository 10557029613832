import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import initialiseAppInsights from './applicationInsights';
import ApolloProvider from './apolloProvider';
import ThemeProvider from './themeProvider';
import ReduxProvider from './reduxProvider';
import ReactRouter from './reactRouter';
import NotistackProvider from './notistackProvider';
import AuthProvider from './authProvider';

const appInsights = initialiseAppInsights();
let appInsightsReactPlugin;
if (appInsights != null) {
  appInsightsReactPlugin = appInsights.reactPlugin;
}

function AppConfiguration({ children }) {
  return (
    <ApolloProvider>
      <ReduxProvider>
        <AuthProvider>
          <ReactRouter>
            <ThemeProvider>
              <CssBaseline />
              <NotistackProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>{children}</MuiPickersUtilsProvider>
              </NotistackProvider>
            </ThemeProvider>
          </ReactRouter>
        </AuthProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default appInsightsReactPlugin
  ? withAITracking(appInsightsReactPlugin, AppConfiguration)
  : AppConfiguration;
