import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import IsLoadingView from '../common/isLoadingView/isLoadingView';

export const NMiTE = React.lazy(() => import('../nmite/nmite'));
export const LogIn = React.lazy(() => import('../log-in'));
export const LogOut = React.lazy(() => import('../log-out'));
export const RedirectLoading = React.lazy(() => import('../redirectLoading/RedirectLoading'));
export const AuthenticationFailed = React.lazy(() => import('../authenticationFailed/authenticationFailed'));

function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<IsLoadingView />}>
        <Switch>
          <Route path="/log-in" component={LogIn} />
          <Route path="/log-out" component={LogOut} />
          <Route path="/auth" component={RedirectLoading} />
          <Route path="/auth-failed" component={AuthenticationFailed} />
          <Route path="/" component={NMiTE} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Routes;
