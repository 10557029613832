export const SET_ASSESSMENT_OVERVIEW_TOGGLE = 'SET_ASSESSMENT_OVERVIEW_TOGGLE';
export const setTableHeaderToggle = header => ({
  type: SET_ASSESSMENT_OVERVIEW_TOGGLE,
  payload: header,
});

export const ASSESSMENT_OVERVIEW_SEARCH_ONCHANGE = 'ASSESSMENT_OVERVIEW_SEARCH_ONCHANGE';
export const assessmentOverviewSearchOnChange = change => ({
  type: ASSESSMENT_OVERVIEW_SEARCH_ONCHANGE,
  payload: change,
});

export const RESET_ASSESSMENT_OVERVIEW_FILTERS = 'RESET_ASSESSMENT_OVERVIEW_FILTERS';
export const resetAssessmentOverviewFilters = () => ({
  type: RESET_ASSESSMENT_OVERVIEW_FILTERS,
});
