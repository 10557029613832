export const ADD_NEW_MODULE = 'ADD_NEW_MODULE';
export const addNewModule = module => ({
  type: ADD_NEW_MODULE,
  payload: module,
});

export const UPDATE_MODULE = 'UPDATE_MODULE';
export const updateModule = module => ({
  type: UPDATE_MODULE,
  payload: module,
});

export const REMOVE_MODULE = 'REMOVE_MODULE';
export const removeModule = module => ({
  type: REMOVE_MODULE,
  payload: module,
});

export const SET_DRAGGED_MODULES = 'SET_DRAGGED_MODULES';
export const setDraggedModulesOrder = data => ({
  type: SET_DRAGGED_MODULES,
  payload: data,
});

export const RESET_COMPULSORY_MODULES = 'RESET_COMPULSORY_MODULES';
export const resetCompulsoryModules = () => ({
  type: RESET_COMPULSORY_MODULES,
});

export const SET_MODULES = 'SET_MODULES';
export const setModules = modules => ({
  type: SET_MODULES,
  payload: modules,
});

export const UPDATE_NEW_MODULE = 'UPDATE_NEW_MODULE';
export const updateNewModule = moduleData => ({
  type: UPDATE_NEW_MODULE,
  payload: moduleData,
});
