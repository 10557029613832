import { SET_CURRENT_CLUSTER, RESET_CURRENT_CLUSTER } from '../../actions/currentCluster/currentClusterActions';

const initialState = {
  id: null,
  moduleCount: null,
  title: '',
  position: null,
  creditPoints: null,
};

const currentClusterReducer = (state = { ...initialState }, action) => {
  let nextState = { ...state };

  switch (action.type) {
    case SET_CURRENT_CLUSTER: {
      nextState = {
        ...action.payload,
      };
      return nextState;
    }
    case RESET_CURRENT_CLUSTER: {
      return initialState;
    }
    default:
      return {
        ...state,
      };
  }
};

export default currentClusterReducer;
