import { UPDATE_MOBILE_DRAWER_VISIBILITY } from '../../actions/mobileNavigation/mobileNavigationActions';

const initialState = {
  isDrawerOpen: false,
};

const mobileReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPDATE_MOBILE_DRAWER_VISIBILITY:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default mobileReducer;
