/**
 * @description Convert a file-type string into a server-friendly string
 * @param {string} fileType The original string
 * @return {string} The server-friendly string
 *
 * @example Matching fileType found
 * convertFileType('text/plain')
 * returns 'txt'
 *
 * @example Matching fileType NOT found
 * convertFileType('unrecognised fileType')
 * returns 'unrecognised fileType' (same value as that passed in)
 */
const convertFileType = fileType => {
  switch (fileType) {
    // Documents
    case 'text/plain':
      return 'txt';
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.oasis.opendocument.text':
      return 'odf';

    // Images
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'png';

    // Videos
    case 'video/3gpp':
      return '3gp';
    case 'video/3gpp2':
      return '3g2';
    case 'video/x-ms-asf':
      return 'asf';
    case 'video/x-msvideo':
      return 'avi';
    case 'video/x-flv':
      return 'flv';
    case 'video/x-f4v':
      return 'f4v';
    case 'video/x-m4v':
      return 'm4v';
    case 'application/mxf':
      return 'mxf';
    case 'model/vnd.mts':
      return 'mts';
    case 'video/mpeg':
      return 'mpeg';
    case 'video/mp4':
    case 'application/mp4':
      return 'mp4';
    case 'video/ogg':
      return 'ogv';
    case 'video/quicktime':
      return 'mov';
    case 'application/vnd.rn-realmedia':
      return 'rm';
    case 'video/vnd.vivo':
      return 'viv';
    case 'video/x-ms-wmv':
      return 'wmv';
    case 'video/x-matroska':
      return 'mkv';
    default:
      return fileType;
  }
};

export default convertFileType;
