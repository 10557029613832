export const SET_CLUSTER_OPTIONS = 'SET_CLUSTER_OPTIONS';
export const setClusterOptions = arr => ({
  type: SET_CLUSTER_OPTIONS,
  payload: arr,
});

export const RESET_CLUSTER_OPTIONS = 'RESET_CLUSTER_OPTIONS';
export const resetClusterOptions = () => ({
  type: RESET_CLUSTER_OPTIONS,
});
