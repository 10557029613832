import {
  StorageURL,
  BlockBlobURL,
  Aborter,
  uploadBrowserDataToBlockBlob,
  AnonymousCredential,
} from '@azure/storage-blob';

/* ************************************************** */
/* ********************* AZURE ********************** */
/* ************************************************** */

export const createBlockBlobUrl = ({ body }) => {
  const pipeline = StorageURL.newPipeline(
    new AnonymousCredential(),
    { retryOptions: { maxTries: 4 } },
  );

  // don't need to build the link here it is called link on body response
  // url: link,

  const blobUrl = { url: `${body.link}`, pipeline };
  const blockBlobUrl = BlockBlobURL.fromBlobURL(blobUrl);

  return blockBlobUrl;
};

export const uploadToAzure = async (file, blockBlobURL) => uploadBrowserDataToBlockBlob(
  Aborter.none, file, blockBlobURL,
  {
    blockSize: 4 * 1024 * 1024, // 4MB block size
    parallelism: 20, // 20 concurrency
    progress: ev => console.log('Progress:', ev),
  },
)
  .then(res => res)
  .catch(err => {
    console.log('Upload to Azure Catch:', err);
  });
