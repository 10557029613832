export const SET_TOGGLED_HEADER_MODULES_TABLE = 'SET_TOGGLED_HEADER_MODULES_TABLE';
export const setModulesToggledHeader = header => ({
  type: SET_TOGGLED_HEADER_MODULES_TABLE,
  payload: header,
});

export const RESET_TOGGLED_HEADER_MODULES_TABLE = 'RESET_TOGGLED_HEADER_MODULES_TABLE';
export const resetModulesToggledHeader = () => ({
  type: RESET_TOGGLED_HEADER_MODULES_TABLE,
});
