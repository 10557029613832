import {
  createMuiTheme,
} from '@material-ui/core/styles';

import NeoSansPro from './fonts/Neo-Sans-Pro.ttf';
import NeoSansProBold from './fonts/Neo-Sans-Pro-Bold.ttf';

const neoSansPro = {
  fontFamily: 'NeoSansPro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('NeoSansPro'),
    url(${NeoSansPro}) format('truetype')
  `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const neoSansProBold = {
  ...neoSansPro,
  fontWeight: 'bold',
  src: `
    local('NeoSansPro'),
    url(${NeoSansProBold}) format('truetype')
  `,
};

const green = '#A7DA36';
const purple = '#883F95';
const purpleLinearGradient = 'linear-gradient(to right,#684599, #883f95)';
const lightGrey = '#A9A9A9';
const lightestGrey = '#F9FAFB';
const inputHeight = 36;

const appTheme = createMuiTheme({
  palette: {
    green,
    purple,
    lightGrey,
    lightestGrey,
    white: '#FFF',
    backgroundColor: '#FAFAFA',
    ctaGradientBackground: purpleLinearGradient,
    navigation: {
      backgroundColor: '#414042',
      backgroundSecondaryColor: '#4D4C4E',
      color: '#FFF',
      focused: '#7f7f7f',
    },
    lineColor: '#EBEFF3',
    basePadding: 30,
    headingBackground: `linear-gradient(#FFF, ${lightestGrey})`,
    mobile: {
      basePadding: 10,
    },
    oddRow: '#FCFCFC',
    evenRow: '#FFF',
    notifications: {
      success: '#43a047',
      error: '#d32f2f',
      warning: '#ffa000',
      info: '#2979ff',
    },
  },
  typography: {
    fontFamily: 'NeoSansPro, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [neoSansPro, neoSansProBold],
        a: {
          color: purple,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: inputHeight,
        borderColor: 'black',
        width: '100%',
        '& $input': {
          padding: '14px 10px 10px 10px',
          fontSize: '14px !important',
        },
        '&$focused $notchedOutline': {
          borderColor: green,
        },
        '&$error $notchedOutline': {
          borderColor: '#ED6347',
          backgroundColor: '#FBEAE5AA',
        },
      },
      adornedEnd: {
        paddingRight: 0,
        height: inputHeight,
        borderRadius: '3px',
      },
      multiline: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '5px 20px 20px 5px',
        minHeight: 108,
        height: 'auto',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    MuiInputAdornment: {
      root: {},
      positionStart: {
        marginRight: 0,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: purple,
      },
    },
    MuiSnackbarContent: {
      root: {
        background: purpleLinearGradient,
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        marginTop: 3,
        marginLeft: 2,
      },
      contained: {
        margin: 'none',
      },
    },
    MuiPickersDay: {
      daySelected: {
        background: purpleLinearGradient,
        backgroundColor: 'none',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'white',
        '&.$Mui-disabled': {
          backgroundColor: lightestGrey,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        background: purpleLinearGradient,
      },
    },
    MuiPickersClock: {
      pin: {
        background: purpleLinearGradient,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        background: purpleLinearGradient,
      },
      thumb: {
        borderColor: purple,
      },
      noPoint: {
        backgroundColor: purple,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '40px 40px 0 40px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px',
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 'bold',
      },
    },
    MuiTable: {
      root: {
        background: 'white',
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: '#893E94',
        '&$checked': {
          color: '#684599',
        },
      },
    },
    MuiChip: {
      colorSecondary: {
        backgroundColor: 'white',
        color: '#dd6b50',
        marginTop: '.5em',
        justifyContent: 'left',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'NeoSansPro',
        fontSize: 13,
        boxShadow: '0 2px 4px 0 rgba(207, 207, 207, 0.5)',
        padding: 10,
        borderLeft: `5px solid ${purple} !important`,
        borderRadius: '3px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    MuiSelect: {
      root: {
        background: 'linear-gradient(#FFFFFF, #F9FAFB)',
      },
      icon: {
        top: 'unset',
        right: '0px !important',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontFamily: 'NeoSansPro',
        fontSize: 13,
        boxShadow: '0 2px 4px 0 rgba(207, 207, 207, 0.5)',
        padding: 10,
        borderLeft: `5px solid ${purple} !important`,
        borderRadius: '3px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  },
});

export default appTheme;
