export const SET_CURRENT_CLUSTER = 'SET_CURRENT_CLUSTER';
export const setCurrentCluster = data => ({
  type: SET_CURRENT_CLUSTER,
  payload: data,
});

export const RESET_CURRENT_CLUSTER = 'RESET_CURRENT_CLUSTER';
export const resetCurrentCluster = () => ({
  type: RESET_CURRENT_CLUSTER,
});
