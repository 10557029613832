import {
  SET_CLUSTER_OPTIONS,
  RESET_CLUSTER_OPTIONS,
} from '../../actions/clusterSelect/clusterSelectActions';

const initialState = {
  globalClusterOptions: [{ value: '' }, { value: 'A' }],
};

const clusterSelectReducer = (state = initialState, action) => {
  const nextState = { ...state };
  switch (action.type) {
    case SET_CLUSTER_OPTIONS:
      nextState.globalClusterOptions = [...action.payload];
      return nextState;

    case RESET_CLUSTER_OPTIONS:
      return initialState;

    default:
      return nextState;
  }
};

export default clusterSelectReducer;
