import { SET_AUTHENTICATION_TOKEN_AND_STATE } from '../../actions/userInfo/userInfoActions';
import { setJwtInLocalStorage, setMemberDetailsInLocalStorage } from '../../helpers/auth/authenticationHelper';
import { LOG_OUT_USER } from '../../actions/logOut/logoutActions';

const initialState = {
  firstName: '',
  lastName: '',
  avatarUrl: '',
  scopes: [],
  token: null,
  uniqueName: '',
  groups: [],
  oid: '',
};

const userInfoReducer = (state = { ...initialState }, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case SET_AUTHENTICATION_TOKEN_AND_STATE:
      nextState.token = action.payload.token;
      setJwtInLocalStorage(action.payload.token);
      setMemberDetailsInLocalStorage(JSON.stringify({
        memberId: action.payload.memberId,
        memberType: action.payload.memberType,
      }));
      return {
        ...nextState,
        firstName: action.payload.decodedJwt.name,
        scopes: action.payload.decodedJwt.roles ? [...action.payload.decodedJwt.roles] : [],
        uniqueName: action.payload.decodedJwt.unique_name,
        groups: action.payload.decodedJwt.groups ? [...action.payload.decodedJwt.groups] : [],
        oid: action.payload.decodedJwt.oid || '',
        memberId: action.payload.memberId,
        memberType: action.payload.memberType,
      };

    case LOG_OUT_USER:
      return {
        ...nextState,
        token: initialState.token,
      };

    default:
      return {
        ...state,
      };
  }
};

export default userInfoReducer;
