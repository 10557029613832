import {
  SET_CURRENT_STUDENT,
  RESET_CURRENT_STUDENT,
  UPDATE_CURRENT_STUDENT,
  SET_FORMIKPROPS_FOR_CURRENT_STUDENT,
  SET_CURRENT_STUDENT_IS_SAVING,
} from '../../actions/currentStudent/currentStudentActions';

const initialState = {
  id: null,
  identifier: '',
  firstName: '',
  middleName: '',
  lastName: '',
  knownAs: '',
  aadObjectId: '',
  mentorStaffId: null,
  mentorStaffFirstName: '',
  mentorStaffLastName: '',
  mentorStaffKnownAs: '',
  mentorStaffAadObjectId: '',
  personalTutorStaffId: null,
  personalTutorStaffFirstName: '',
  personalTutorStaffLastName: '',
  personalTutorStaffKnownAs: '',
  personalTutorStaffAadObjectId: '',
  programmeTitle: '',
  programmeId: null,
  cohortTitle: '',
  groupTitle: '',
  dob: '',
  age: '',
  ageRange: '',
  ageRangeId: null,
  email: '',
  personalEmail: '',
  telephone: '',
  altTelephone: '',
  accommodationStatus: '',
  termTimeAddressLine1: '',
  termTimeAddressLine2: '',
  termTimeAddressLine3: '',
  termTimeAddressLine4: '',
  termTimeAddressPostCode: '',
  homeAddressLine1: '',
  homeAddressLine2: '',
  homeAddressLine3: '',
  homeAddressLine4: '',
  homeAddressPostCode: '',
  primaryEmergencyContactName: '',
  primaryEmergencyContactRelationship: '',
  primaryEmergencyContactTelephone: '',
  primaryEmergencyContactAltTelephone: '',
  secondaryEmergencyContactName: '',
  secondaryEmergencyContactRelationship: '',
  secondaryEmergencyContactTelephone: '',
  secondaryEmergencyAltTelephone: '',
  gender: '',
  specifiedGender: '',
  disability: '',
  specifiedDisability: '',
  nationality: '',
  specifiedNationality: '',
  sexualOrientation: '',
  specifiedSexualOrientation: '',
  religion: '',
  specifiedReligion: '',
  ethnicity: '',
  specifiedEthnicity: '',
  maritalStatus: '',
  specifiedMaritalStatus: '',
  isArchived: false,
  formikProps: null,
  isSet: false,
  isSaving: false,
};

const currentStudentReducer = (state = { ...initialState }, action) => {
  let nextState = { ...state };

  switch (action.type) {
    case SET_CURRENT_STUDENT:
      nextState = {
        ...action.payload,
        isSet: true,
      };
      return nextState;

    case UPDATE_CURRENT_STUDENT:
      nextState[action.payload.key] = action.payload.data;
      return nextState;

    case RESET_CURRENT_STUDENT:
      return initialState;

    case SET_FORMIKPROPS_FOR_CURRENT_STUDENT:
      nextState.formikProps = action.payload;
      return nextState;

    case SET_CURRENT_STUDENT_IS_SAVING:
      nextState.isSaving = action.payload;
      return nextState;

    default:
      return {
        ...state,
      };
  }
};

export default currentStudentReducer;
// export default initialState
// export { , initialState };
