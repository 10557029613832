import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { removeNotification } from '../../actions/notifications/notificationActions';

class Notifier extends Component {
  displayed = [];

  componentDidUpdate() {
    const { notifications = [] } = this.props;
    if (!notifications.length) this.displayed = [];

    notifications.forEach(({
      key, message, options = {}, dismissed = false,
    }) => {
      if (dismissed) {
        this.props.closeSnackbar(key);
        return;
      }
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) return;
      // Display snackbar using notistack
      this.props.enqueueSnackbar(message, {
        key,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        ...options,
        onClose: (event, reason, keyParam) => {
          if (options.onClose) {
            options.onClose(event, reason, keyParam);
          }
        },
        onExited: (event, keyParam) => {
          this.props.removeSnackbar(keyParam);
          this.removeDisplayed(keyParam);
        },
      });
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(key);
    });
  }

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = id => {
    this.displayed = this.displayed.filter(key => id !== key);
  };

  render() {
    return null;
  }
}

Notifier.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      options: PropTypes.shape({
        autoHideDuration: PropTypes.number,
        variant: PropTypes.string,
      }),
    }),
  ),
  closeSnackbar: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
};

Notifier.defaultProps = {
  notifications: [],
};

const mapStateToProps = store => ({
  notifications: store.notifications.notifications,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    removeSnackbar: removeNotification,
  },
  dispatch,
);

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Notifier));
