import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { history } from './configureStore';
import settings from '../../settings';

let appInsights;
export default () => {
  if (settings.appInsightsInstrumentationKey == null) {
    return null;
  }

  console.info('Initialising telemetry');

  const reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: settings.appInsightsInstrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
      disableFetchTracking: false,
      autoTrackPageVisitTime: true,
      appId: settings.appId,
    },
  });

  // appInsights.context.application.build
  // appInsights.context.application.ver

  appInsights.loadAppInsights();

  return {
    appInsights,
    reactPlugin,
  };
};

export const updateAppInsightsUser = userId => {
  if (appInsights == null) {
    return;
  }

  if (userId != null) {
    console.log('Set Authenticated User Context');
    appInsights.setAuthenticatedUserContext(userId);
  } else {
    console.log('Clear Authenticated User Context');
    appInsights.clearAuthenticatedUserContext();
  }
};
