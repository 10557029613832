export default {
  // Injected values from build
  apiUrl: process.env.REACT_APP_API_URL,
  tenantId: process.env.REACT_APP_TENANT_ID,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  authUrl: process.env.REACT_APP_AUTH_URI,
  appInsightsInstrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
  appId: process.env.REACT_APP_ID,

  // AAD Group IDs
  aadGroups: {
    catA: 'bb0703f7-e2d9-4e45-95f7-5995be8b8502',
    catB: '6899a0ee-a945-409f-be2f-dd8529d3408f',
    generalAcademic: '008db3a6-bd88-4c2e-b619-11cf1b86b791',
    moduleLead: '1cd68976-394f-455d-bfd4-0f9bf905b521',
    programmeLead: '1e30731d-75e9-4c73-a573-9a6ac6ce2223',
    chiefAcademicOfficer: '7c40838d-01d4-496b-b850-5169d81ab90e',
    incidentResponseTeam: 'c8e6c142-c3ba-43ac-8c15-3e0a65846490',
    student: 'dd981ede-66b1-486e-b0f4-d2ea53957564',
  },

  // Application Constants
  applicationName: 'New Model Institute for Technology and Engineering',
  applicationAcronym: 'NMITE',

};
