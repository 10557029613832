import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  success: { background: theme.palette.notifications.success },
  error: { background: theme.palette.notifications.error },
  warning: { background: theme.palette.notifications.warning },
  info: { background: theme.palette.notifications.info },
}));

export default useStyles;
