const initialState = {
  menuItems: [
    {
      title: 'Generic Information',
      path: 'generic-information',
      id: 0,
    },
    {
      title: 'Sensitive Information',
      path: 'sensitive-information',
      id: 1,
    },
    {
      title: 'Application Journey',
      path: 'application-journey',
      id: 2,
    },
    {
      title: 'Student Journey',
      path: 'student-journey',
      id: 3,
    },
    {
      title: 'Student Submissions \uFF06 Requests',
      path: 'student-submissions-and-requests',
      id: 4,
    },
    {
      title: 'Communications',
      path: 'communications',
      id: 5,
    },
    {
      title: 'Activity',
      path: 'activity',
      id: 6,
    },
  ],
};

const furtherDetailsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
};

export default furtherDetailsReducer;
