export const SET_TOGGLED_HEADER = 'SET_TOGGLED_HEADER';
export const setTableHeaderToggle = header => ({
  type: SET_TOGGLED_HEADER,
  payload: header,
});

export const GET_STUDENT_TYPES = 'GET_STUDENT_TYPES';
export const getStudentTypes = resp => ({
  type: GET_STUDENT_TYPES,
  payload: resp,
});
