import {
  UPLOAD_FILE_BEGIN,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  REMOVE_FILE_FROM_UPLOAD_ARRAY,
  CLEAN_FILE_UPLOAD_ARRAY,
  CLEAR_ERROR,
} from '../../actions/uploadFileToItem/uploadFileToItemActions';

const initialState = {
  componentStates: {},
  tempFileIds: [], // for sending up on handleSubmit of form
  error: false,
};

const uploadFileToItemReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPLOAD_FILE_BEGIN:
      return {
        ...state,
        componentStates: {
          ...state.componentStates,
          [action.payload]: {
            fileUploading: true,
          },
        },
        error: false,
      };

    case UPLOAD_FILE_SUCCESS: {
      let currentFiles;
      if (state.componentStates[action.payload.componentId] != null) {
        if (state.componentStates[action.payload.componentId].files != null) {
          currentFiles = state.componentStates[action.payload.componentId].files;
        }
        currentFiles = [];
      } else {
        currentFiles = [];
      }

      return {
        ...state,
        componentStates: {
          ...state.componentStates,
          [action.payload.componentId]: {
            fileUploading: false,
            files: [...currentFiles, ...action.payload.response],
          },
        },
        tempFileIds: [...state.tempFileIds, ...action.payload.response.map(file => file.id)],
        error: false,
      };
    }

    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        componentStates: {
          ...state.componentStates,
          [action.payload.componentId]: {
            fileUploading: false,
          },
        },
        error: true,
      };

    case CLEAN_FILE_UPLOAD_ARRAY:
      return {
        ...state,
        componentStates: {},
        tempFileIds: [],
        error: false,
      };

    case REMOVE_FILE_FROM_UPLOAD_ARRAY:
      return {
        ...state,
        componentStates: {
          ...state.componentStates,
          [action.payload.componentId]: {
            ...state.componentStates[action.payload.componentId],
            files: state.componentStates[action.payload.componentId].files.filter(
              file => file.id !== action.payload.fileId,
            ),
          },
        },
        tempFileIds: state.tempFileIds.filter(fileId => fileId !== action.payload.fileId),
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default uploadFileToItemReducer;
