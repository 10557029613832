export const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';
export const setCurrentStudent = data => ({
  type: SET_CURRENT_STUDENT,
  payload: data,
});

export const SET_CURRENT_STUDENT_IS_SAVING = 'SET_CURRENT_STUDENT_IS_SAVING';
export const setCurrentStudentIsSaving = bool => ({
  type: SET_CURRENT_STUDENT_IS_SAVING,
  payload: bool,
});

export const UPDATE_CURRENT_STUDENT = 'UPDATE_CURRENT_STUDENT';
export const updateCurrentStudent = (key, data) => ({
  type: UPDATE_CURRENT_STUDENT,
  payload: { key, data },
});

export const RESET_CURRENT_STUDENT = 'RESET_CURRENT_STUDENT';
export const reSetCurrentStudent = () => ({
  type: RESET_CURRENT_STUDENT,
});

export const SET_FORMIKPROPS_FOR_CURRENT_STUDENT = 'SET_FORMIKPROPS_FOR_CURRENT_STUDENT';
export const setSubmitFunction = formikProps => ({
  type: SET_FORMIKPROPS_FOR_CURRENT_STUDENT,
  payload: formikProps,
});
